import {
    Score,
    AssessmentScoreRank,
} from "@/features/lifestyle-medicine-assessment/types.ts";
import { cn } from "@/lib/utils.ts";

const scoreColorMap: Record<AssessmentScoreRank, string> = {
    excellent: "text-green-700",
    very_good: "text-lime-700",
    average: "text-yellow-700",
    below_average: "text-red-700",
};

const scoreDescriptionMap: Record<AssessmentScoreRank, string> = {
    excellent: "Excellent",
    very_good: "Very Good",
    average: "Average",
    below_average: "Below Average",
};

type LifestyleMedicineAssessmentScoreProps = {
    score: Score;
    className?: string;
};

export function LifestyleMedicineAssessmentScore({
    score,
    className,
}: LifestyleMedicineAssessmentScoreProps) {
    return (
        <p className={cn("font-bold", scoreColorMap[score.rank], className)}>
            {scoreDescriptionMap[score.rank]} ({score.score}/50)
        </p>
    );
}
